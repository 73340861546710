<template>
  <div>
    <AdminFarmList />
  </div>
</template>
<script>
import AdminFarmList from '@/views/components/Admin/Farmlist';

export default {
  components: {
    AdminFarmList,
  },
};
</script>
